import Vue from 'vue';
import Router from 'vue-router';
import admin from '@/core/middlewares/admin.js';
import classrooms from '@/core/middlewares/classrooms.js';

Vue.use(Router);

export default new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            redirect: '/dashboard',
            component: () => import('@/view/layout/Layout'),
            children: [
                {
                    path: '/dashboard',
                    name: 'dashboard',
                    component: () => import('@/view/pages/Dashboard.vue')
                },
                {
                    path: '/classe/:classeUserId',
                    name: 'classe',
                    props: true,
                    component: () => import('@/view/pages/Classe.vue')
                },
                {
                    path: ':classeUserId/lesson/:id',
                    name: 'lesson',
                    component: () => import('@/view/pages/Lesson.vue')
                },
                {
                    path: 'cataclasses/managelogue',
                    name: 'catalogue',
                    component: () => import('@/view/pages/Catalogue.vue')
                },
                {
                    path: 'classrooms',
                    name: 'classrooms',
                    component: () => import('@/view/pages/Classrooms.vue'),
                    meta: {
                        middleware: [classrooms]
                    }
                },
                {
                    path: 'classroom/:id',
                    name: 'classroom',
                    component: () => import('@/view/pages/Classroom.vue'),
                    meta: {
                        middleware: [classrooms]
                    }
                },
                {
                    path: 'classroom/signature/:visioId',
                    name: 'classroom-signature',
                    component: () => import('@/view/pages/ClassroomSignature.vue')
                    // meta: {
                    //     middleware: [classrooms]
                    // }
                },
                {
                    path: 'classroom/videoconference/:id',
                    name: 'classroom-visio',
                    component: () => import('@/view/pages/ClassroomVisio.vue'),
                    meta: {
                        middleware: [classrooms]
                    }
                },
                {
                    path: 'classroom/videoconference/attente/:visioId',
                    name: 'classroom-visio-waiting',
                    component: () => import('@/view/pages/WaitingRoom.vue')
                    // meta: {
                    // middleware: [classrooms]
                    // }
                }
            ]
        },
        {
            name: 'admin',
            path: '/admin',
            redirect: 'admin/dashboard',
            component: () => import('@/view/layout/admin/AdminLayout'),
            children: [
                {
                    name: 'admin/dashboard',
                    path: 'dashboard',
                    component: () => import('@/view/pages/admin/Dashboard')
                },
                {
                    name: 'trainee.create',
                    path: 'trainees/create',
                    component: () => import('@/view/pages/admin/trainee/CreateTrainee.vue')
                },
                {
                    name: 'classes/new',
                    meta: {
                        middleware: [admin]
                    },
                    path: 'classes/new',
                    component: () => import('@/view/pages/admin/NewClasses')
                },
                {
                    name: 'classes/view',
                    meta: {
                        middleware: [admin]
                    },
                    path: 'classes/view/:id',
                    component: () => import('@/view/pages/admin/ViewClasses')
                },
                {
                    name: 'classes/edit',
                    meta: {
                        middleware: [admin]
                    },
                    path: 'classes/edit',
                    component: () => import('@/view/pages/admin/EditClasses')
                },
                {
                    name: 'classes/manage',
                    path: 'classes/manage',
                    component: () => import('@/view/pages/admin/ManageClasses')
                },
                {
                    name: 'classes/update',
                    meta: {
                        middleware: [admin]
                    },
                    path: 'classes/update',
                    component: () => import('@/view/pages/admin/FormEditClasses')
                },
                {
                    name: 'lessons/new',
                    meta: {
                        middleware: [admin]
                    },
                    path: 'lessons/new',
                    component: () => import('@/view/pages/admin/NewLesson')
                },
                {
                    name: 'lessons/edit',
                    meta: {
                        middleware: [admin]
                    },
                    path: 'lessons/edit',
                    component: () => import('@/view/pages/admin/EditLesson')
                },
                {
                    name: 'lessons/update',
                    meta: {
                        middleware: [admin]
                    },
                    path: 'lessons/update',
                    component: () => import('@/view/pages/admin/FormEditLesson')
                },
                {
                    name: 'lessonItems/editor',
                    meta: {
                        middleware: [admin]
                    },
                    path: 'lessonItems/editor',
                    component: () => import('@/view/pages/admin/EditorLessonItems')
                },
                {
                    name: 'lessonItems',
                    path: 'lessonItems',
                    component: () => import('@/view/pages/admin/DisplayLessonItems')
                },
                {
                    name: 'client/assign',
                    path: 'customer/assign',
                    component: () => import('@/view/pages/admin/AssignClientClasses')
                },
                {
                    name: 'user/assign',
                    path: 'user/assign',
                    component: () => import('@/view/pages/admin/AssignUserClasse')
                },
                {
                    name: 'trainee-class.assign',
                    path: 'trainee-class/assign',
                    component: () => import('@/view/pages/admin/trainee-class/AssignTraineeClass')
                },
                {
                    name: 'classes-customers.link',
                    path: '/classe/:classeUserId',
                    component: () => import('@/view/pages/admin/entry-link/Link')
                },
                {
                    name: 'classes-intern.link',
                    path: '/classe/:classesCustomersId/link',
                    component: () => import('@/view/pages/admin/entry-link/Link')
                },
                {
                    name: 'classes-customers.queue',
                    path: '/classe/:classesCustomersId/queue',
                    component: () => import('@/view/pages/admin/queue/ManageQueue')
                },
                {
                    name: 'classes-customers.assign-error',
                    path: '/classe/:classesCustomersId/erreur',
                    component: () => import('@/view/pages/admin/assign-error/AssignError')
                },
                {
                    name: 'admin/settings',
                    path: '/admin/settings',
                    component: () => import('@/view/content/widgets/admin/SettingsTableWidget')
                },
                {
                    name: 'admin/settings/questions_interactives',
                    path: '/admin/settings/questions_interactives',
                    component: () => import('@/view/pages/admin/settings/QuestionsInteractives')
                }
            ]
        },
        {
            path: '/custom-error',
            name: 'error',
            component: () => import('@/view/pages/error/Error.vue'),
            children: [
                {
                    path: 'error-1',
                    name: 'error-1',
                    component: () => import('@/view/pages/error/Error-1.vue')
                }
            ]
        },
        {
            path: '*',
            redirect: '/404'
        },
        {
            // the 404 route, when none of the above matches
            path: '/404',
            name: '404',
            component: () => import('@/view/pages/error/Error-1.vue')
        }
    ]
});
