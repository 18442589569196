import ApiService from '@/core/services/api.service';

export const SET_USERS = 'setUsers';
export const GET_USERS_BY_RIGHT = 'getUsersByRight';
export const SET_ERROR = 'setError';

const state = {
    errors: null,
    users: []
};

const getters = {
    users(state) {
        return state.users;
    }
};

const actions = {
    async [GET_USERS_BY_RIGHT](context, data) {
        try {
            const result = await ApiService.query('users/right/' + data.service_id + '/' + data.right_id);
            context.commit(SET_USERS, result.data);
            return result;
        } catch (error) {
            throw error;
        }
    }
};

const mutations = {
    [SET_USERS](state, users) {
        state.users = users;
    },
    [SET_ERROR](state, error) {
        state.errors = error;
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
