import ApiService from '@/core/services/api.service';

export const GET_MESSAGES = 'getMessages';
export const SEND_MESSAGE = 'sendMessage';
export const SET_MESSAGES = 'setMessages';
export const SET_ERROR = 'setError';

const state = {
    errors: null,
    messages: {}
};

const getters = {
    getMessages(state) {
        return state.messages;
    },
    getErrors(state) {
        return state.errors;
    },
};

const actions = {
    [GET_MESSAGES](context, data) {
        return new Promise(resolve => {
            ApiService.query('academy/chat/' + data.id + '/' + data.userId).then(result => {
                context.commit(SET_MESSAGES, result.data);
                resolve(result);
            });
        });
    },
    [SEND_MESSAGE](context, data) {
        return new Promise((resolve, reject) => {
            ApiService.post('academy/chat/' + data.id + '/message/' + data.receiver_id, data).then(result => {
                context.commit(SET_MESSAGES, result.data);
                resolve(result);
            }).catch(error => {
                context.commit(SET_ERROR, error);
                reject(error);
            });
        });
    },
};

const mutations = {
    [SET_MESSAGES](state, messages) {
        state.messages = messages;
    },
    [SET_ERROR](state, error) {
        state.errors = error;
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
