import ApiService from '@/core/services/api.service';

export const GET_SURVEY_ITEMS_USERS = 'getSurveyItemsUsers';
export const SET_SURVEY_ITEMS_USERS = 'setSurveyItemsUsers';
export const SET_ERROR = 'setError';

const state = {
    errors: null,
    surveyItemsUsers: []
};

const getters = {
    getSurveyItemsUsers(state) {
        return state.surveyItemsUsers;
    }
};

const actions = {
    async [GET_SURVEY_ITEMS_USERS](context, data) {
        try {
            const result = await ApiService.query('academy/surveyItemsUsers/' + data.classUserId + '/' + data.lessonId);
            context.commit(SET_SURVEY_ITEMS_USERS, result.data);
            return result;
        } catch (error) {
            throw error;
        }
    },
    
};

const mutations = {
    [SET_SURVEY_ITEMS_USERS](state, surveyItemsUsers) {
        state.surveyItemsUsers = surveyItemsUsers;
    },
    [SET_ERROR](state, error) {
        state.errors = error;
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
