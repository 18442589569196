// France
export const locale = {
    TRANSLATOR: {
        SELECT: 'choisissez votre langue'
    },
    MENU: {
        NEW: 'Nouveau',
        ACTIONS: 'Actes',
        CREATE_POST: 'Créer un nouveau Post',
        PAGES: 'Pages',
        FEATURES: 'Fonctionnalités',
        APPS: 'Applications',
        DASHBOARD: 'Tableau de Bord'
    },
    AUTH: {
        GENERAL: {
            OR: 'Ou',
            SUBMIT_BUTTON: 'Soumettre',
            NO_ACCOUNT: 'Ne pas avoir de compte?',
            SIGNUP_BUTTON: 'Registre',
            FORGOT_BUTTON: 'Mot de passe oublié',
            BACK_BUTTON: 'Back',
            PRIVACY: 'Privacy',
            LEGAL: 'Legal',
            CONTACT: 'Contact'
        },
        LOGIN: {
            TITLE: 'Créer un compte',
            BUTTON: 'Sign In'
        },
        LOGOUT: {
            TITLE: 'Déconnexion',
            BUTTON: 'Se déconnecter'
        },
        FORGOT: {
            TITLE: 'Forgot Password?',
            DESC: 'Enter your email to reset your password',
            SUCCESS: 'Your account has been successfully reset.'
        },
        REGISTER: {
            TITLE: 'Sign Up',
            DESC: 'Enter your details to create your account',
            SUCCESS: 'Your account has been successfuly registered.'
        },
        INPUT: {
            EMAIL: 'Email',
            FULLNAME: 'Fullname',
            PASSWORD: 'Mot de passe',
            CONFIRM_PASSWORD: 'Confirm Password',
            USERNAME: "Nom d'utilisateur"
        },
        VALIDATION: {
            INVALID: "{{name}} n'est pas valide",
            REQUIRED: '{{name}} est requis',
            MIN_LENGTH: '{{name}} minimum length is {{min}}',
            AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
            NOT_FOUND: 'The requested {{name}} is not found',
            INVALID_LOGIN: 'The login detail is incorrect',
            REQUIRED_FIELD: 'Required field',
            MIN_LENGTH_FIELD: 'Minimum field length:',
            MAX_LENGTH_FIELD: 'Maximum field length:',
            INVALID_FIELD: 'Field is not valid'
        }
    },
    ECOMMERCE: {
        COMMON: {
            SELECTED_RECORDS_COUNT: "Nombre d'enregistrements sélectionnés: ",
            ALL: 'All',
            SUSPENDED: 'Suspended',
            ACTIVE: 'Active',
            FILTER: 'Filter',
            BY_STATUS: 'by Status',
            BY_TYPE: 'by Type',
            BUSINESS: 'Business',
            INDIVIDUAL: 'Individual',
            SEARCH: 'Search',
            IN_ALL_FIELDS: 'in all fields'
        },
        ECOMMERCE: 'éCommerce',
        CUSTOMERS: {
            CUSTOMERS: 'Les clients',
            CUSTOMERS_LIST: 'Liste des clients',
            NEW_CUSTOMER: 'Nouveau client',
            DELETE_CUSTOMER_SIMPLE: {
                TITLE: 'Suppression du client',
                DESCRIPTION: 'Êtes-vous sûr(e) de vouloir supprimer définitivement ce client ?',
                WAIT_DESCRIPTION: 'Nous procédons à la suppression...',
                MESSAGE: 'Le client a été supprimé'
            },
            DELETE_CUSTOMER_MULTY: {
                TITLE: 'Supprimer les clients',
                DESCRIPTION: 'Êtes-vous sûr(e) de supprimer définitivement les clients sélectionnés?',
                WAIT_DESCRIPTION: 'Les clients suppriment ...',
                MESSAGE: 'Les clients sélectionnés ont été supprimés'
            },
            UPDATE_STATUS: {
                TITLE: 'Le statut a été mis à jour pour les clients sélectionnés',
                MESSAGE: 'Le statut des clients sélectionnés a été mis à jour avec succès'
            },
            EDIT: {
                UPDATE_MESSAGE: 'Le client a été mis à jour',
                ADD_MESSAGE: 'Le client a été créé'
            }
        }
    },
    CLASS: {
        NAME: {
            LABEL: 'Classe : ',
            LABELCLASSROOM: 'Classroom : ',
            INPUT: 'Entrez le nom de la classe'
        },
        CREATED_AT: 'Créée le {date} par {name}',
        UPDATED_AT: {
            DEFINED: 'Dernière mise à jour : ',
            UNDEFINED: "Cette classe n'a jamais été mis à jour"
        },
        ADD: 'Créer une classe',
        UPDATE: 'Enregister les modifications',
        BACK: 'Retour à la liste',
        DESCRIPTION: {
            LABEL: 'Description : ',
            INPUT: 'Entrez la description de la classe'
        },
        TUTOR: {
            LABEL: 'Tuteur : ',
        },
        DATE: {
            LABEL: 'Date : ',
        },
        EDIT: {
            UPDATE_MESSAGE: 'La classe a été mis à jour',
            ADD_MESSAGE: 'La classe a été créé'
        },
        VERSION: {
            LABEL: 'Version : '
        },
        CERTIFICATE: {
            LABEL: "Texte présent sur l'attestation finale : ",
            INPUT: "Entrez le texte qui apparaîtra sur l'attestation finale",
            EXEMPLE: "Voir l'attestation exemple"
        },
        TEASER: {
            LABEL: "Teaser :",
            INPUT: "lien YouTube"
        },
        THEME: {
            LABEL: 'Thème de formation : ',
        },
        ON: {
            ONLINE: 'En ligne',
            GO_OFFLINE: 'Rendre indisponible',
            OFFLINE: 'Indisponible en ligne',
            GO_ONLINE: 'Publier',
            MODAL: {
                TOOGLE_ON_WARNING: 'Une fois publiée, la classe pourra être affectée aux clients. \n Toute modification sera impossible.',
                TOOGLE_ON_TITLE: 'Êtes-vous sûr(e) de vouloir publier cette classe ?',
                TOOGLE_ON_FAIL:
                    'Nous sommes désolés, il semble que nous rencontrons des difficultés pour publier la classe.\n\n Veuillez vérifier que vous disposez d\'au moins un cours et que chaque cours possède une évaluation finale.',
                TOOGLE_OFF_WARNING:
                    'Une fois indisponible en ligne, la classe ne pourra plus être affectée aux clients ni consultée par les stagiaires.',
                TOOGLE_OFF_TITLE: 'Êtes-vous sûr(e) de vouloir rendre indisponible cette classe ?',
                TOOGLE_OFF_FAIL:
                    'Nous sommes désolés, il semble que nous rencontrons des difficultés pour rendre indisponnible la classe.\n\n Veuillez réessayer plus tard'
            }
        },
        LINEAR: {
            TRUE: 'Parcours libre ',
            FALSE: 'Parcours ordonné',
            INFOTRUE: "Libre au stagiaire de suivre l'ordre des cours.",
            INFOFALSE: "Oblige le stagiaire à suivre l'ordre des cours."
        },
        CATALOG: {
            TRUE: {
                LABEL: 'Classe catalogue',
                INFO: "La classe est générique. Elle sera proposée au catalogue."
            },
            FALSE: {
                LABEL: 'Classe catalogue',
                INFO: 'La classe est spécifique. Elle ne sera pas proposée au catalogue.'
            }
        },
        CLASSROOM: {
            LABEL: 'Classroom',
            INFO: 'Cette option permet de planifier des sessions de formation sous la supervision d\'un tuteur.'
        },
        GOAL: {
            LABEL: 'Objectif de note : ',
            INFO: 'Pourcentage de réussite minimal pour valider la classe.'
        },
        DURATION: {
            LABEL: 'Durée : ',
            INFO: 'Temps nécessaire pour terminer la classe.',
            UNIT: 'minutes'
        },
        STATS: {
            STUDENT: 'stagiaires',
            FINISH: 'Nombre de stagaire ayant fini la formation.',
            WORK: 'Nombre de stagaire réalisant la formation.',
            ALL: 'Total',
            SUCCESS: 'Pourcentage de réussite'
        },
        MODAL: {
            TITLE: 'Êtes-vous sûr(e) de vouloir supprimer cette classe ?',
            INFO: 'Attention ! Si vous supprimez cette classe, vous supprimerez également tous les cours qui lui sont associés.'
        },
        MAX_INTERNS: {
            LABEL: 'Nombre de stagiaires maximum : ',
            INFO: 'Nombre de stagiaires maximum par session de formation.'
        },
        SUPERVISEURS: {
            LABEL: 'Superviseurs autorisés : ',
            INFO: 'Liste des superviseurs autorisés à voir les classerooms associées à cette classe.'
        },
    },
    LESSON: {
        NAME: 'Cours',
        EMPTY: 'La classe ne possède pas de cours.',
        ADD: 'Créer un cours',
        SEE_CLASS: 'Voir la classe complète',
        FORMTITLE: "Création d'un cours pour la classe : {title}",
        CREATED_AT: 'Créée le {date}',
        UPDATED_AT: {
            DEFINED: ' Dernière date de mise à jour : {date}',
            UNDEFINED: "Cette classe n'a jamais été mis à jour"
        },
        TITLE: {
            LABEL: 'Titre du cours : ',
            INPUT: 'Entrez ici le titre du cours'
        },
        MIN_DURATION: {
            LABEL: 'Temps d’apprentissage minimum (en minute): ',
            INFO: 'Ce paramètre est facultatif. Laisser vide si vous ne souhaitez pas le définir.',
        },
        LINEAR: {
            TRUE: {
                LABEL: 'Cours libre',
                INFO: "Libre au stagiaire de suivre ou non l'ordre du cours"
            },
            FALSE: {
                LABEL: 'Cours ordonné',
                INFO: "Oblige le stagiaire à suivre l'ordre du cours"
            }
        },
        DESCRIPTION: {
            LABEL: 'Description : ',
            INPUT: 'Entrez ici la description du cours'
        }
    },
    LESSON_ITEM: {
        START: 'Commencer la composition du cours',
        EMPTY: "Le cours ne possède pas d'élement"
    },
    PRESENTATION: 'présentation',
    MEDIA: {
        SELECT: 'Choisissez une image'
    },
    CONTROLLERS: {
        ADD: 'Ajouter',
        UPDATE: 'Modifier',
        DELETE: 'Supprimer',
        DELETE_CONFIRM: 'Supprimer quand même',
        CANCEL: 'Annuler',
        YES: 'Oui',
        NO: 'Non',
        CONFIRM: 'Êtes-vous sûr(e) ?',
        VALIDATION: 'Valider',
        ERASE: 'Effacer les modifications',
        BACK: 'Retour',
        CLOSE: 'Fermer',
        BROWSE: 'Parcourir',
        DOWNLOAD_MODEL: 'Télécharger le modèle',
        IMPORT: 'Importer'
    },
    FORM: {
        LABEL: {
            FILE: 'Charger un fichier',
            USERS_CUSTOMER: "Rattacher à l'établissement : "
        },
        INPUT: {
            FILE: 'Déposez votre fichier ici'
        },
        INFO: {
            FILE: 'Le fichier doit être au format {format}, Attention : seule la première feuille de calcul sera prise en compte !',
            USERS_CUSTOMER: '', // info générique
            USERS_CUSTOMER_IMPORT:
                "Si vous sélectionnez un établissement, tous les stagiaires lui seront rattachés. Dans le cas contraire le système utilisera la colonne 'Code établissement' de votre fichier Excel." // info spé
        }
    }
};
