import Vue from 'vue'
import VueDatePicker from '@mathieustan/vue-datepicker';
import '@mathieustan/vue-datepicker/dist/vue-datepicker.min.css';

Vue.use(VueDatePicker, {
    lang: 'fr'
})

export default {
    name: 'vue-datepicker',
    install(Vue) {
        Vue.component('vue-datepicker', VueDatePicker)
    }
}