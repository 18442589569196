import ApiService from '@/core/services/api.service';

export const LIST_ALL_THEMES = 'listAllThemes';
export const SET_ALL_THEMES = 'setAllThemes';
export const SET_ERROR = 'setError';

const state = {
    errors: null,
    allThemes: []
};

const getters = {
    getAllThemes(state) {
        return state.allThemes;
    }
};

const actions = {
    [LIST_ALL_THEMES](context) {
        return new Promise(resolve => {
            ApiService.get(process.env.VUE_APP_API_URL, 'themes').then(result => {
                context.commit(SET_ALL_THEMES, result.data);
                resolve(result);
            });
        });
    }
};

const mutations = {
    [SET_ALL_THEMES](state, data) {
        state.allThemes = data;
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
