<template>
    <router-view></router-view>
</template>

<style lang="scss">
// 3rd party plugins css
@import '~bootstrap-vue/dist/bootstrap-vue.css';
@import '~perfect-scrollbar/css/perfect-scrollbar.css';
@import '~socicon/css/socicon.css';
@import '~animate.css';
@import '~@fortawesome/fontawesome-free/css/all.css';
@import '~line-awesome/dist/line-awesome/css/line-awesome.css';
@import 'assets/plugins/flaticon/flaticon.css';
@import 'assets/plugins/flaticon2/flaticon.css';
@import 'assets/plugins/keenthemes-icons/font/ki.css';

// Main demo style scss
@import 'assets/sass/style.vue';

// Check documentation for RTL css
// Update HTML with RTL attribute at public/index.html
/*@import "assets/css/style.vue.rtl";*/
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
    font-size: 13px !important;
    color: #3f4254;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 13px !important;
    margin: 5px;
}

.v-data-table-header th {
    white-space: nowrap;
}

</style>

<script>
/* import { OVERRIDE_LAYOUT_CONFIG } from "@/core/services/store/config.module"; */

export default {
    name: 'MetronicVue',
    mounted() {
        /**
         * this is to override the layout config using saved data from localStorage
         * remove this to use config only from static json (@/core/config/layout.config.json)
         */
        /* this.$store.dispatch(OVERRIDE_LAYOUT_CONFIG); */
    }
};
</script>
