import ApiService from '@/core/services/api.service';

export const LIST_INTERACTIVEITEMS = 'listInteractiveItems';
export const SET_INTERACTIVEITEM = 'setInteractiveItem';
export const SET_INTERACTIVEITEMS = 'setInteractiveItems';
export const SET_ERROR = 'setError';

const state = {
    errors: null,
    interactiveitem: {},
    interactiveitems: {}
};

const getters = {
    getInteractiveItem(state) {
        return state.interactiveitem;
    },
    getInteractiveItems(state) {
        return state.interactiveitems;
    }
};

const actions = {
    [LIST_INTERACTIVEITEMS](context, data) {
        return new Promise((resolve) => {
            ApiService.query('academy/interaction', data).then((result) => {
                context.commit(SET_INTERACTIVEITEMS, result.data);
                resolve(result);
            });
        });
    }
};

const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error;
    },
    [SET_INTERACTIVEITEM](state, interactiveitem) {
        state.interactiveitem = interactiveitem;
    },
    [SET_INTERACTIVEITEMS](state, interactiveitems) {
        state.interactiveitems = interactiveitems;
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
