import ApiService from '@/core/services/api.service';

export const LIST_CLASSROOMS = 'listClassrooms';
export const GET_CLASSROOM = 'getClassroom';
export const UPDATE_CLASSROOM = 'updateClassroom';
export const SET_CLASSROOMS = 'setClassrooms';
export const SET_CLASSROOM = 'setClassroom';
export const SET_ERROR = 'setError';

const state = {
    errors: null,
    classrooms: {},
    classroom: {}
};

const getters = {
    getClassrooms(state) {
        return state.classrooms;
    },
    getClassroom(state) {
        return state.classroom;
    },
    updateClassroom(state) {
        return state.classroom;
    }
};

const actions = {
    [LIST_CLASSROOMS](context, data) {
        return new Promise(resolve => {
            ApiService.query('academy/classroom/active/' + data).then(result => {
                context.commit(SET_CLASSROOMS, result.data);
                resolve(result);
            });
        });
    },
    [GET_CLASSROOM](context, data) {
        return new Promise(resolve => {
            ApiService.query('academy/classroom/' + data).then(result => {
                context.commit(SET_CLASSROOMS, result.data);
                resolve(result);
            });
        });
    },
    [UPDATE_CLASSROOM](context, data) {
        return new Promise((resolve, reject) => {
            ApiService.post('academy/classroom/' + data.id, data)
                .then(result => {
                    context.commit(SET_CLASSROOM, result.data);
                    resolve(result);
                })
                .catch(error => reject(error.response.data));
        });
    },
};

const mutations = {
    [SET_CLASSROOMS](state, classrooms) {
        state.classrooms = classrooms;
    },
    [SET_CLASSROOM](state, classroom) {
        state.classroom = classroom;
    },
    [SET_ERROR](state, error) {
        state.errors = error;
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
