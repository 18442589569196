import ApiService from '@/core/services/api.service';

export const LIST_LESSON = 'listLesson';
export const SET_LESSON = 'setLesson';
export const SET_ERROR = 'setError';

const state = {
    errors: null,
    lesson: {}
};

const getters = {
    getLesson(state) {
        return state.lesson;
    }
};

const actions = {
    [LIST_LESSON](context, data) {
        return new Promise(resolve => {
            ApiService.get(process.env.VUE_APP_API_URL, 'academy/lessons/' + data).then(result => {
                context.commit(SET_LESSON, result.data);
                resolve(result);
            });
        });
    }
};

const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error;
    },
    [SET_LESSON](state, lesson) {
        state.lesson = lesson;
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
