import ApiService from '@/core/services/api.service';

export const LIST_LINK = 'listLink';
export const SET_LINK = 'setLink';
export const SET_ERROR = 'setError';

const state = {
    errors: null,
    allLinks: []
};

const getters = {
    getLink(state) {
        return state.allLinks;
    }
};

const actions = {
    [LIST_LINK](context, data) {
        return new Promise(resolve => {
            ApiService.query(`academy/clientclasses/${data.classCustomerId}/links`, data).then(result => {
                context.commit(SET_LINK, result.data);
                resolve(result);
            });
        });
    }
};

const mutations = {
    [SET_LINK](state, links) {
        state.allLinks = links;
    },
    [SET_ERROR](state, error) {
        state.errors = error;
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
