import ApiService from '@/core/services/api.service';

// action types
export const VERIFY_AUTH = 'verifyAuth';
export const REFRESH_TOKEN = 'refreshToken';
export const LOGOUT = 'logOut';

// mutation types
export const PURGE_AUTH = 'logOut';
export const SET_AUTH = 'setUser';
export const SET_TOKEN_LIFETIME = 'setTokenLifetime';
export const SET_ERROR = 'setError';

const state = {
    errors: null,
    user: {},
    isAuthenticated: false,
    tokenLifetime: null
};

const getters = {
    currentUser(state) {
        return state.user;
    },
    isAuthenticated(state) {
        return state.isAuthenticated;
    },
    tokenLifetime(state) {
        return state.tokenLifetime;
    }
};

const actions = {
    [LOGOUT](context) {
        context.commit(PURGE_AUTH);
    },
    [VERIFY_AUTH](context) {
        let user = {};
        return new Promise((resolve, reject) => {
            ApiService.get(process.env.VUE_APP_API_URL, 'user')
                .then((result) => {
                    context.commit(PURGE_AUTH);
                    user = result.data.data;
                    ApiService.get(process.env.VUE_APP_API_URL, 'academy/users/' + result.data.data.id).then((result) => {
                        user.role = result.data.name;
                        context.commit(SET_AUTH, user);
                        resolve(result);
                    });
                })
                .catch((error) => {
                    // get current route
                    const currentRoute = window.location.href;
                    window.location = `${process.env.VUE_APP_PLATEFORME_URL}/login?redirect=${currentRoute}`;
                    reject(error);
                });
        });
    },
    [REFRESH_TOKEN](context) {
        return new Promise((resolve, reject) => {
            ApiService.query('refresh-token')
                .then(({ data }) => {
                    context.commit(SET_TOKEN_LIFETIME, data.lifetime);
                    resolve(data);
                })
                .catch((response) => {
                    context.commit(SET_ERROR, response);
                    context.commit(PURGE_AUTH);
                    reject(response);
                });
        });
    }
};

const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error;
    },
    [SET_AUTH](state, user) {
        state.isAuthenticated = true;
        state.user = user;
        state.user.avatar = state.user.avatar
            ? state.user.avatar
            : `https://avatars.dicebear.com/api/initials/${state.user.firstname}%20${state.user.lastname}.svg`;
        state.errors = {};
    },
    [PURGE_AUTH](state) {
        state.isAuthenticated = false;
        state.user = {};
        state.errors = {};
    },
    [SET_TOKEN_LIFETIME](state, tokenLifetime) {
        state.tokenLifetime = tokenLifetime;
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
