import Vue from 'vue';
import './plugins/fontawesome';
import App from './App.vue';
import router from './router';
import store from '@/core/services/store';
import ApiService from '@/core/services/api.service';
import { VERIFY_AUTH } from '@/core/services/store/auth.module';
import { RESET_LAYOUT_CONFIG } from '@/core/services/store/config.module';
import vueExcelXlsx from './core/plugins/vue-excel-xlsx'
import VueDatePicker from '@/core/plugins/vue-datepicker'

Vue.config.productionTip = false;

// Global 3rd party plugins
import 'popper.js';
import 'tooltip.js';
import PerfectScrollbar from 'perfect-scrollbar';
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from 'clipboard';
window.ClipboardJS = ClipboardJS;

// Vue 3rd party plugins
import i18n from '@/core/plugins/vue-i18n';
import vuetify from '@/core/plugins/vuetify';
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import VTooltip from 'v-tooltip'
import '@/core/plugins/portal-vue';
import '@/core/plugins/bootstrap-vue';
import '@/core/plugins/perfect-scrollbar';
import '@/core/plugins/highlight-js';
import '@/core/plugins/inline-svg';
import '@/core/plugins/apexcharts';
import '@/core/plugins/metronic';
import '@/core/plugins/treeselect';
import '@/core/plugins/vee-validate';
import '@mdi/font/css/materialdesignicons.css';

// API service init
ApiService.init();

router.beforeEach(async (to, from, next) => {
    // Ensure we checked auth before each page load.
    await store.dispatch(VERIFY_AUTH);

    // reset config to initial state
    store.dispatch(RESET_LAYOUT_CONFIG);
    // Scroll page to top on every route change
    setTimeout(() => {
        window.scrollTo(0, 0);
    }, 100);

    if (to.meta.middleware) {
        const middleware = Array.isArray(to.meta.middleware) ? to.meta.middleware : [to.meta.middleware];
        const context = {
            from,
            next,
            router,
            to
        };
        const nextMiddleware = nextFactory(context, middleware, 1);

        return middleware[0]({
            ...context,
            next: nextMiddleware
        });
    } else {
        return next();
    }
});

function nextFactory(context, middleware, index) {
    const subsequentMiddleware = middleware[index]; // If no subsequent Middleware exists,
    // the default `next()` callback is returned.

    if (!subsequentMiddleware) return context.next;

    return (...parameters) => {
        // Run the default Vue Router `next()` callback first.
        context.next(...parameters); // Then run the subsequent Middleware with a new
        // `nextMiddleware()` callback.

        const nextMiddleware = nextFactory(context, middleware, index + 1);
        subsequentMiddleware({
            ...context,
            next: nextMiddleware
        });
    };
}

var filter = function(text, length, clamp) {
    clamp = clamp || '...';
    var node = document.createElement('div');
    node.innerHTML = text;
    var content = node.textContent;
    return content.length > length ? content.slice(0, length) + clamp : content;
};

Vue.filter('truncate', filter);

Vue.use(VTooltip)

Vue.use(Vuetify);

new Vue({
    router,
    store,
    i18n,
    vuetify,
    vueExcelXlsx,
    VueDatePicker,
    render: h => h(App)
}).$mount('#app');
