import Vue from 'vue';
import VeeValidate, { Validator } from 'vee-validate';
import fr from 'vee-validate/dist/locale/fr';

Vue.use(VeeValidate, {
    // This is the default
    inject: true,
    // Important to name this something other than 'fields'
    fieldsBagName: 'veeFields',
    // This is not required but avoids possible naming conflicts
    errorBagName: 'veeErrors'
});

Validator.localize('fr', fr);
