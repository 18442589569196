import Vue from 'vue';
import Vuex from 'vuex';
import auth from './auth.module';
import htmlClass from './htmlclass.module';
import config from './config.module';
import breadcrumbs from './breadcrumbs.module';
import profile from './profile.module';
import classe from './api/classe.service';
import lesson from './api/lesson.service';
import lessonItems from './api/lessonitems.service';
import client from './api/client.service';
import trainee from './api/trainee.service';
import users from './api/users.service';
import interactive_items from './api/interactive-items.service';
import classe_users from './api/classe_users.service';
import secondary_site from './api/secondary_site.service';
import theme from './api/theme.service';
import client_classe from './api/client-classe.service';
import entry_link from './api/entry-link.service';
import classe_customer_queue from './api/client-classe-queue.service';
import classe_customer_assign_error from './api/client-classe-assign-error.service';
import classroom from './api/classroom.service';
import videoconference from './api/videoconference.service';
import chat from './api/chat.service';
import surveyItemsUsers from './api/survey-items-users.service';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        auth,
        chat,
        lessonItems,
        htmlClass,
        config,
        breadcrumbs,
        profile,
        classe,
        classe_users,
        client_classe,
        lesson,
        client,
        trainee,
        users,
        secondary_site,
        theme,
        interactive_items,
        entry_link,
        classe_customer_queue,
        classe_customer_assign_error,
        classroom,
        videoconference,
        surveyItemsUsers
    }
});
